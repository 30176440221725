<template>
  <div class="section">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input
          label="团长姓名"
          clearable
          v-model="searchParam.legalPersonName"
        />
        <v-input
          label="团长联系方式"
          clearable
          v-model="searchParam.mobileNum"
        />
        <v-select
          clearable
          :options="buyTypeOps"
          v-model="searchParam.buyType"
          label="团购类型"
        />
        <v-select
          clearable
          :options="groupStatusOps"
          v-model="searchParam.groupStatus"
          label="团购状态"
        />
        <v-input
          label="产品名称："
          clearable
          v-model="searchParam.goodsTitle"
        />
        <v-date-picker
          label="选择时间"
          v-model="time"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看电话" type="text" @click="getDecPhone(scope.row)" />
        <v-button text="详情" type="text" @click="toView(scope.row)" />
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toCancel(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      title="联系电话"
      v-model="dialogFormVisible"
      width="50%"
      @confirm="doCancel"
    >
      <p>{{ tel }}</p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  issueGroupBuyingForManaURL,
  delGroupInfoURL,
  getGroupBuyDecPhoneUrl,
} from "./api.js";
import {
  buyTypeMap,
  setBuyTypeOps,
  groupStatusMap,
  setGroupStatusOps,
} from "./map.js";
import { createAlinkVNode } from "@/utils/utils.js";

export default {
  data() {
    return {
      buyTypeOps: setBuyTypeOps(),
      groupStatusOps: setGroupStatusOps(),
      dialogFormVisible: false,
      tel: "",
      searchParam: {
        legalPersonName: "",
        mobileNum: "",
        buyType: undefined,
        groupStatus: undefined,
        goodsTitle: "",
        beginTime: "",
        endTime: "",
      },
      tableUrl: issueGroupBuyingForManaURL,
      headers: [
        {
          prop: "legalPersonName",
          label: "团长姓名",
        },
        // {
        //   prop: "id",
        //   label: "商品编号",
        // },
        {
          prop: "mobileNum",
          label: "团长联系方式",
        },
        {
          prop: "buyType",
          label: "团购类型",
          formatter(row, prop) {
            return buyTypeMap[row[prop]];
          },
        },
        {
          prop: "goodsTitle",
          label: "商品标题",
        },
        {
          prop: "nowCost",
          label: "现价",
        },
        {
          prop: "goodsSpecification",
          label: "规格",
        },
        {
          prop: "buyStartTime",
          label: "发布时间",
        },
        {
          prop: "getAddress",
          label: "提货点",
        },
        {
          prop: "status",
          label: "团购状态",
          formatter(row, prop) {
            return groupStatusMap[row[prop]];
          },
        },
        {
          prop: "groupCount",
          label: "参团人数",
          align: "center",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: row[prop],
              cb: () => this.toRouter(row),
            }),
        },
      ],
    };
  },
  computed: {
    ...mapState("app", ["userInfo"]),
    time: {
      get() {
        return !this.searchParam.beginTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.beginTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.beginTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {
    doCancel() {
      this.dialogFormVisible = false;
    },
    // 获取商品icon图
    async getDecPhone(row) {
      let params = {
        id: row.id,
      };
      let res = await this.$axios.get(getGroupBuyDecPhoneUrl, { params });
      if (res.code == 200) {
        this.tel = res.data;
        this.dialogFormVisible = true;
      }
    },
    toAdd() {
      this.$router.push({
        name: "businessNewGroupForm",
      });
    },
    toView(data) {
      this.$router.push({
        name: "businessNewGroupForm",
        query: { id: data.id, isView: true },
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "businessNewGroupForm",
        query: { id: data.id },
      });
    },
    toCancel(data) {
      this.$confirm("你确定要删除吗", "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${delGroupInfoURL}`, null, {
              params: {
                id: data.id,
                optUserId: this.userInfo.userId,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("删除成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },

    toRouter(data) {
      this.$router.push({
        name: "businessNewGroupOrder",
        query: {
          id: data.id,
          goodsTitle: data.goodsTitle,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
}
</style>
